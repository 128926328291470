<template>
  <div class="form-input-box">
    <div class="form-box">
      <table class="form-input-table">
        <tr>
          <td>
            <label class="form-label">{{label}}</label>
            <i v-if="required" class="required-txt">*</i>
          </td>
          <td @click="openPicker">
            <p class="select-txt" :class="{'select-txt-placeholder':!dateVal,'select-txt-placeholder-disable':disable}">{{dateVal?dateVal:"请选择"}}</p>
          </td>
        </tr>
      </table>
    </div>
    <i class="select-icon"></i>
    <mt-datetime-picker ref="picker" :startDate="startDate" :endDate="endDate" class="custom-date" type="date" v-model="dateValTemp" confirmText="完成" @confirm="dateConfirm()"
      @touchmove.native.stop.prevent>
    </mt-datetime-picker>
  </div>
</template>

<script>
import { DatetimePicker } from "mint-ui";
import { formatDate, isDate } from "@/script/tools";

export default {
  name: "x-form-date",
  data () {
    return {
      startDate: new Date("1900/01/01"),
      endDate: new Date(),
      dateVal: null,
      dateValTemp: null,
      selectedValue: "",
      popupShow: true
    };
  },
  props: {
    label: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    defaultDate: {
      type: String,
      default: null
    },
    disable: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    openPicker () {
      if (!this.disable) {
        if (this.selectedValue) {
          this.dateValTemp = this.selectedValue;
        } else {
          const tempDate = new Date(this.defaultDate || new Date());
          tempDate.setFullYear(tempDate.getFullYear() - 1);
          this.dateValTemp = tempDate;
          setTimeout(() => {
            this.dateValTemp = isDate(this.defaultDate) && this.defaultDate ? this.defaultDate : new Date();
          }, 0);
        }
        this.$refs.picker.open();
      }
    },
    dateConfirm () {
      this.dateVal = formatDate(this.dateValTemp);
      this.$emit("change", this.dateVal);
    }
  },
  watch: {
    defaultDate (newVal) {
      if (isDate(newVal) && newVal) {
        this.dateVal = newVal;
        this.dateValTemp = newVal;
      } else {
        this.dateVal = null;
        this.dateValTemp = new Date();
      }
    }
  },
  components: {
    [DatetimePicker.name]: DatetimePicker
  }
};
</script>

<style>
.custom-date .picker-toolbar {
  display: flex;
  justify-content: space-between;
}
.custom-date .mint-datetime-action {
  padding: 0 0.16rem;
  width: auto;
}
</style>

<style scoped>
.form-input-box {
  display: flex;
  align-items: center;
  padding: 0.16rem 0;
  border-bottom: solid 0.01rem #f6f8fb;
}
.form-box {
  flex: 1;
}
.form-input-table {
  width: 100%;
}
.form-label {
  font-size: 0.14rem;
  color: #737885;
}
.required-txt {
  margin-left: 0.04rem;
  font-size: 0.14rem;
  color: #f75959;
}
.select-txt {
  font-size: 0.14rem;
  text-align: right;
  color: #30353d;
}
.select-txt-placeholder-disable {
  color: #737885;
}
.select-txt-placeholder {
  color: #afb2b9;
}
.select-icon {
  margin-left: 0.1rem;
  width: 0.07rem;
  height: 0.12rem;
  background: url('~@images/select_icon.png') no-repeat center/0.07rem 0.12rem;
}
</style>
